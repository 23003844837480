<template>
  <div class="relative xl:h-screen lg:overflow-hidden bg-primary-500 xl:bg-white
  before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%]
  before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg]
  before:bg-primary-100 before:rounded-[100%] after:hidden after:xl:block after:content-[''] after:w-[57%]
  after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform
  after:rotate-[-4.5deg] after:bg-primary-500 after:rounded-[100%]">
    <div v-if="resetSuccess" class="intro-y mt-4 text-green-600">
      {{ trans('leakblock.forgot.successMessage') }}
    </div>
    <div class="!max-w-[1800px] container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <div class="hidden min-h-screen flex-col xl:flex">
          <div class="my-auto">
            <img class="intro-y -mt-16 w-1/2" src="/build/images/illustrations/login.svg" alt="Leakblock Login">
            <div class="intro-y mt-10 text-4xl font-medium leading-tight text-white" v-html="getHeadlineText()"></div>
            <div class="intro-y mt-5 text-lg text-white text-opacity-70">
              {{ trans('leakblock.login.protectData') }}
            </div>
          </div>
        </div>
        <div class="mt-10 xl:my-10 flex xl:h-screen py-16 xl:py-5 xl:my-0 xl:h-auto xl:py-0">
          <div class="mx-auto my-auto w-full rounded-md bg-white px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none">
            <transition name="fade" mode="out-in">
              <div v-if="showSuccessMessage" key="success" class="text-center">
                <div class="flex justify-center mb-8">
                  <img class="intro-y -mt-16 w-1/4" src="/build/images/illustrations/mail_sent.svg" alt="Email sent">
                </div>
                <h2 class="intro-y text-2xl font-bold mb-4">{{ successMessage }}</h2>
                <p class="intro-y mb-4">{{ getSuccessSubtext() }}</p>
                <button @click="handleSuccessAction" class="intro-y bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-600 transition">
                  {{ getSuccessButtonText() }}
                </button>
              </div>
              <div v-else key="form">
                <h2 class="intro-y text-center text-2xl font-bold xl:text-left xl:text-3xl">
                  {{ getFormTitle() }}
                </h2>
                <div v-if="sessionExpired" class="intro-y mt-2 text-red-600">
                  {{ trans('leakblock.login.sessionExpired') }}
                </div>
                <div class="intro-y mt-2 text-center text-slate-400 xl:hidden">
                  {{ trans('leakblock.login.protectData') }}
                </div>
                <form @submit.prevent="submitForm">
                  <div class="intro-y mt-8">
                    <input type="email" v-model="email" name="email" id="email" :placeholder="trans('leakblock.login.emailPlaceholder')" required class="intro-y block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    <template v-if="formType === 'register'">
                      <input type="text" v-model="firstname" name="firstname" id="firstname" :placeholder="trans('leakblock.register.firstname')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                      <input type="text" v-model="lastname" name="lastname" id="lastname" :placeholder="trans('leakblock.register.lastname')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    </template>
                    <template v-if="formType !== 'forgot'">
                      <input type="password" v-model="password" name="password" id="password" :placeholder="trans('leakblock.login.passwordPlaceholder')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    </template>
                    <input v-if="formType === 'register'" type="password" v-model="confirmPassword" name="confirm-password" id="confirm-password" :placeholder="trans('leakblock.register.confirmPasswordPlaceholder')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    <div v-if="formType === 'register'" class="intro-y mt-4 flex text-xs text-slate-600 sm:text-sm">
                      <div class="mr-auto flex items-center">
                        <input type="checkbox" required class="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary-500 focus:ring-opacity-20 [&[type='radio']]:checked:bg-primary-500 [&[type='radio']]:checked:border-primary-500 [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary-500 [&[type='checkbox']]:checked:border-primary-500 [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed mr-2 border" id="accept-agb">
                        <label class="cursor-pointer select-none" for="accept-agb" v-html="trans('leakblock.register.acceptAGB', { 'agbLink': trans(`leakblock.footer.termsUrl`) })">
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="errorMessage" class="intro-y mt-4 text-red-600 md:max-w-[400px]">{{ errorMessage }}</div>
                  <div v-if="formType === 'login'" class="intro-y mt-4 flex text-xs text-slate-600 sm:text-sm">
                    <a @click.prevent="setFormType('forgot')" href="#" class="cursor-pointer">{{ trans('leakblock.login.forgotPasswordLink') }}</a>
                  </div>
                  <div class="intro-y mt-5 text-center xl:mt-8 xl:text-left">
                    <SubmitButton :is-submitting="isSubmitting">
                      {{ getSubmitButtonText() }}
                    </SubmitButton>
                    <button v-if="formType !== 'forgot'" type="button" @click="toggleForm" class="mt-3 w-full xl:w-40 xl:mt-0 xl:ml-3 border text-slate-500 px-4 py-3 align-top rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none transition duration-200 hover:bg-secondary/20">
                      {{ formType === 'login' ? trans('leakblock.login.signUpLink') : trans('leakblock.register.loginLink') }}
                    </button>
                    <button v-else type="button" @click="setFormType('login')" class="mt-3 w-full xl:w-40 xl:mt-0 xl:ml-3 border text-slate-500 px-4 py-3 align-top rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none transition duration-200 hover:bg-secondary/20">
                      {{ trans('leakblock.register.loginLink') }}
                    </button>
                  </div>
                </form>
                <div class="intro-y mt-8 flex items-center justify-center">
                  <div class="w-full flex items-center">
                    <div class="flex-grow border-t border-slate-200"></div>
                    <div class="px-4 text-slate-500 text-sm">{{ trans('leakblock.login.orContinueWith') }}</div>
                    <div class="flex-grow border-t border-slate-200"></div>
                  </div>
                </div>

                <div class="intro-y mt-6 flex justify-center">
                  <button
                      @click="handleGoogleLogin"
                      type="button"
                      class="w-full inline-flex items-center justify-center px-6 py-3 rounded-md shadow-sm border border-slate-200 bg-white hover:bg-slate-50 transition-colors duration-200 gap-2 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none"
                  >
                    <svg class="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                      <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                      <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                      <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                    </svg>
                    {{ trans('leakblock.login.continueWithGoogle') }}
                  </button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { trans } from "../../../js/utilities/leakblock-translations";
import axios from 'axios';
import SubmitButton from "../components/Buttons/SubmitButton.vue";

const props = defineProps({
  csrf_token_register: String,
  loginUrl: String,
  registerNewUrl: String,
  dashboardUrl: String,
  forgotPasswordUrl: String
});

const formType = ref('login');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const firstname = ref('');
const lastname = ref('');
const errorMessage = ref('');
const successMessage = ref('');
const isSubmitting = ref(false);
const showSuccessMessage = ref(false);
const resetSuccess = ref(false);
const sessionExpired = ref(false);

const setFormType = (type) => {
  formType.value = type;
  errorMessage.value = '';
};

const toggleForm = () => {
  setFormType(formType.value === 'login' ? 'register' : 'login');
};

const handleGoogleLogin = () => {
  const currentLang = localStorage.getItem('preferredLanguage') || 'en';
  window.location.href = `/connect/google?preferredLanguage=${currentLang}`;
};

const validateForm = () => {
  if (!email.value) {
    errorMessage.value = trans('leakblock.validation.enterEmail');
    return false;
  }
  if (formType.value !== 'forgot' && !password.value) {
    errorMessage.value = trans('leakblock.validation.enterPassword');
    return false;
  }
  if (formType.value === 'register') {
    if (!firstname.value || !lastname.value) {
      errorMessage.value = trans('leakblock.validation.fillAllFields');
      return false;
    }
    if (password.value !== confirmPassword.value) {
      errorMessage.value = trans('leakblock.register.passwordsDoNotMatch');
      return false;
    }
    if (password.value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(password.value)) {
      errorMessage.value = trans('leakblock.register.passwordRequirements');
      return false;
    }
  }
  return true;
};

const submitForm = async () => {
  if (!validateForm()) return;

  isSubmitting.value = true;
  errorMessage.value = '';

  try {
    let response;
    const startTime = Date.now();
    const currentLang = localStorage.getItem('preferredLanguage') || 'en';

    switch (formType.value) {
      case 'login':
        // First ensure server knows about our language preference
        await axios.post(`/api/language/${currentLang}`);

        response = await axios.post(props.loginUrl, {
          email: email.value,
          password: password.value,
          language: currentLang // Add language to login request
        });

        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        } else {
          // Use the current language for redirect
          window.location.href = `/${currentLang}/dashboard`;
        }
        break;
      case 'register':
        response = await axios.post(props.registerNewUrl, {
          email: email.value,
          password: password.value,
          firstname: firstname.value,
          lastname: lastname.value,
          token: props.csrf_token_register
        });
        break;
      case 'forgot':
        response = await axios.post(props.forgotPasswordUrl, { email: email.value });
        break;
    }

    const elapsedTime = Date.now() - startTime;
    const remainingTime = Math.max(0, 1500 - elapsedTime);

    // Ensure the spinner shows for at least 2 seconds
    await new Promise(resolve => setTimeout(resolve, remainingTime));

    if (response.data.success) {
      if (formType.value === 'login') {
        window.location.href = props.dashboardUrl;
      } else {
        successMessage.value = response.data.success;
        showSuccessMessage.value = true;
      }
    }
  } catch (error) {
    errorMessage.value = error.response?.data?.error || trans('leakblock.error.occurredDuring', { 'action': trans(`leakblock.${formType.value}.actionText`) });
  } finally {
    isSubmitting.value = false;
  }
};

const checkForOAuthErrors = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const oauthError = urlParams.get('oauth_error');

  if (oauthError) {
    try {
      const response = await axios.get('/api/oauth/error');
      if (response.data.error) {
        errorMessage.value = response.data.error;
        formType.value = 'login'; // Ensure we're on login form
      }
    } catch (err) {
      console.error('Failed to fetch OAuth error:', err);
      errorMessage.value = trans('leakblock.error.general');
    } finally {
      // Clean up URL
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, newUrl);
    }
  }
};

const getSuccessSubtext = () => {
  switch (formType.value) {
    case 'register':
      return trans('leakblock.register.success');
    case 'forgot':
      return trans('leakblock.forgot.email');
    default:
      return '';
  }
};

const getSuccessButtonText = () => {
  switch (formType.value) {
    case 'register':
      return trans('leakblock.login.goToLogin');
    case 'forgot':
      return trans('leakblock.login.backToLogin');
    default:
      return '';
  }
};

const handleSuccessAction = () => {
  setFormType('login');
  showSuccessMessage.value = false;
};

const getFormTitle = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.headline');
    case 'register': return trans('leakblock.register.headline');
    case 'forgot': return trans('leakblock.forgot.headline');
  }
};

const getHeadlineText = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.headlineTextHtml');
    case 'register': return trans('leakblock.register.headlineTextHtml');
    case 'forgot': return trans('leakblock.forgot.headlineTextHtml');
  }
};

const getSubmitButtonText = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.signInButton');
    case 'register': return trans('leakblock.register.registerButton');
    case 'forgot': return trans('leakblock.forgot.resetButton');
  }
};

onMounted(() => {
  checkForOAuthErrors();

  const urlParams = new URLSearchParams(window.location.search);
  sessionExpired.value = urlParams.get('session_expired') === 'true';

  // Clean up session_expired parameter
  if (sessionExpired.value) {
    const newUrl = window.location.pathname + window.location.hash;
    window.history.replaceState({}, document.title, newUrl);
  }
});
</script>